import React from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { getFormFields, isFormValid, onEmailChange, onNumberChange, onTextChange } from 'src/Utils/formHelper';
import { Button } from 'primereact/button';
import { trimObj } from 'src/Utils';
import doctorRegisterFormFields from './patientRegisterForm.json';
import Service from 'src/Service';
import { saveUserSession } from './../store/actions'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from './../session/index'
import LoadingComponent from 'src/LoadingComponent';




class VerifyOTP extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isShowVerifyOtp: false,
            otp: null,
            otpErrMsg: null,
        }
        this.service = new Service();
    }

    onVerify = () => {
        let { otp } = this.state;
        debugger
        if (otp && otp.length == 6) {
            this.setState({
                isLoading: true
            });
            const url = `/patient/verify`;
            debugger
            this.service.post(url, { otp, _id: this.props.userId }, true).then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false
                    }, () => {
                        this.props.saveUserSession(res.res);
                        this.props.history.push('/')
                    })
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Error', detail: res.message, life: 3000 });
                }
            }).catch(e => {
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            })



        } else {
            this.setState({
                otpErrMsg: 'Invalid OTP.'
            })


        }
    }


    render() {
        console.log(this.props)
        const { otp, otpErrMsg } = this.state;
        return (<>
            <div className='doctor-login-form'>
                <div>
                    <InputText value={otp}
                        type="text"
                        min={6}
                        max={6}
                        maxLength={6}
                        minLength={6}
                        autoComplete="new-password"
                        className='ma-w100p  p-inputtext-style1'
                        onChange={(e) => {
                            this.setState({
                                otp: e.target.value
                            })
                        }} />
                    {otpErrMsg && <p className="p-error">{otpErrMsg}</p>}
                    <Toolbar className="ma-toolbar ma-mt20"
                        right={() => {
                            return (<Button label='Verify' className=''
                                onClick={this.onVerify}
                            />)
                        }}
                    ></Toolbar>
                </div>
            </div>
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
            {
                this.state.isLoading && <>
                    <LoadingComponent />
                </>
            }

        </>)
    }
}


const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
});


export default connect(mapStateToProps, {
    saveUserSession,
})(Authentication(withRouter(VerifyOTP)));
