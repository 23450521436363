import React, { Component } from 'react'
import { Dialog } from 'primereact/dialog';
import Service from 'src/Service';
import { Toolbar } from 'primereact/toolbar';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import templateFields from './template.json';
import LoadingComponent from 'src/LoadingComponent';
import { Specialization } from 'src/Utils/constants';
import { getFormFields, isFormValid, onEmailChange, onNumberChange, onTextChange } from 'src/Utils/formHelper';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from '../session/index'
import Header from 'src/Header';
import './styles.scss'
import { InputTextarea } from 'primereact/inputtextarea';
import ClassicEditor from 'src/ClassicEditor';
import { trimObj } from 'src/Utils';

class CreateTemplate extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(templateFields, this.props.template ? this.props.template : {});
        this.state = {
            template: this.formFields.data,
            formValidations: this.formFields.formValidations,
        }
        this.service = new Service();
    }

    saveTemplate = () => {
        let templateData = trimObj(this.state.template)
        const formStatus = isFormValid(templateFields, this.formFields.formValidations, templateData);
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
        } else {
            let url = `/template/save`
            if (this.props.template) {
                url = `/template/update`
            }


            this.setState({
                isLoading: true,
            });

            this.service.post(url, templateData, true).then((res) => {

                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                    }, () => {
                        debugger

                        this.props.onSave(this.props.template ? true : false);
                    })
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
                }

            }).catch(e => {
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            })

        }

    }

    componentDidMount() {
        if (this.props.isSessionChecked && !this.props.authenticated) {
            this.props.history.push('/login')
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSessionChecked != this.props.isSessionChecked && (this.props.isSessionChecked && !this.props.authenticated)) {
            this.props.history.push('/login')
        }

    }

    footer = () => {
        return (<div>
            <Button label={`${this.props.template ? 'Update' : 'Save'} Template`} className='ma-m-lr10'
                onClick={this.saveTemplate}
            />
        </div>)
    }


    render() {
        const { template, formValidations } = this.state;

        console.log(this.state, 'state-appointment')
        return (<>
            <Dialog
                header={`${this.props.template ? 'Edit' : 'Create'} Template`}
                blockScroll={true}
                draggable={false}
                closeOnEscape={true}
                dismissableMask={true}
                visible={true}
                footer={this.footer}
                closable={true}
                style={{ width: '90%', height: '90vh' }}
                onHide={this.props.onHide}>

                <div>
                    <div className="p-grid ma-mt20">

                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label'>Name<span className='ma-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                            <InputText value={template.name}
                                className='ma-w100p p-inputtext-style1'
                                onChange={(e) => { onTextChange(e.target.value, 'name', this, templateFields, template, formValidations, 'template', 'formValidations') }} />
                            {formValidations && !formValidations.fields['name'].isValid && <p className="p-error">{formValidations.fields['name'].errorMsg}</p>}
                        </div>
                    </div>
                    <div className="p-grid ">
                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label' >Description<span className='ma-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                            <InputTextarea value={template.description}
                                rows={3}
                                className='ma-w100p p-inputtext-style1'
                                onChange={(e) => { onTextChange(e.target.value, 'description', this, templateFields, template, formValidations, 'template', 'formValidations') }} />
                            {formValidations && !formValidations.fields['description'].isValid && <p className="p-error">{formValidations.fields['description'].errorMsg}</p>}
                        </div>
                    </div>
                    <div className="p-grid ma-mt20">

                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label'>Subject<span className='ma-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                            <InputText value={template.subject}

                                className='ma-w100p p-inputtext-style1'
                                onChange={(e) => { onTextChange(e.target.value, 'subject', this, templateFields, template, formValidations, 'template', 'formValidations') }} />
                            {formValidations && !formValidations.fields['subject'].isValid && <p className="p-error">{formValidations.fields['subject'].errorMsg}</p>}
                        </div>
                    </div>
                    <div className="p-grid ">
                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label' >Message<span className='ma-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                            <ClassicEditor
                                data={template.message}
                                onChange={(value) => { onTextChange(value, 'message', this, templateFields, template, formValidations, 'template', 'formValidations') }}
                            />

                            {formValidations && !formValidations.fields['message'].isValid && <p className="p-error">{formValidations.fields['message'].errorMsg}</p>}
                        </div>
                    </div>
                    {/* <div dangerouslySetInnerHTML={{ __html: template.template }}></div> */}

                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}

            </Dialog>
        </>
        )
    }
}






const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    isSessionChecked: state?.user?.isSessionChecked,
});


export default connect(mapStateToProps, {

})(Authentication(withRouter(CreateTemplate)));
