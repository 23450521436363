import { ACTIONS } from '../actionTypes';
import axios from 'axios';
import { API_URL } from 'src/Service/config';

export const getPermissionRequested = () => ({ type: ACTIONS.GET_PERMISSIONS_REQUESTED });
const getPermissionReqSucceeded = (data) => ({ type: ACTIONS.GET_PERMISSIONS_SUCCEEDED, data });
const getPermissionRequestFailed = () => ({ type: ACTIONS.GET_PERMISSIONS_FAILED });

const url = `${API_URL}/authorize/getPermissions`;

export const getPermissionDetails = () => {
    return axios({
        method: 'get',
        url,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        }
    });
};

export const getPermissionList = () => (dispatch) => {
    dispatch(getPermissionRequested());
    getPermissionDetails()
        .then((res) => {
            if (res.data) {
                dispatch(getPermissionReqSucceeded(res.data.data));
            } else {
                dispatch(getPermissionRequestFailed());
            }
        })
        .catch((e) => {
            if (e.response && e.response.status == 401) {
                localStorage.clear();
                window.location.reload();
            } else {
                dispatch(getPermissionRequestFailed());
            }
        });
};
