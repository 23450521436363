import React, { Component } from 'react'
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';
import _ from 'lodash';
import { Checkbox } from 'primereact/checkbox';
import Service from 'src/Service';
import { Toolbar } from 'primereact/toolbar';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Steps } from 'primereact/steps';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import userFields from './user.json';
import LoadingComponent from 'src/LoadingComponent';
import { BasicLazyParams, Specialization } from 'src/Utils/constants';
import { getFormFields, isFormValid, onEmailChange, onChipsChange, onNumberChange, onTextChange } from 'src/Utils/formHelper';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from '../session/index'
import Header from 'src/Header';
import './styles.scss'
import { InputTextarea } from 'primereact/inputtextarea';
import ClassicEditor from 'src/ClassicEditor';
import { Chips } from 'primereact/chips';
import { trimObj } from 'src/Utils';
import { MultiSelect } from 'primereact/multiselect';
import { RadioButton } from 'primereact/radiobutton';
import XLSX from 'xlsx';


/* list of supported file types */
const SheetJSFT = ['xlsx', 'xlsb', 'xlsm', 'xls', 'csv']
    .map(function (x) {
        return '.' + x;
    })
    .join(',');

/* generate an array of column objects */
const make_cols = (refstr) => {
    let o = [],
        C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
    return o;
};



class CreateUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            templates: [],
            templateId: null,
            signatures: [],
            signatureId: null,
            configId: null,
            currentStep: 0,
            message: {
                subject: '',
                body: '',
                signature: '',



            },
            tags: [],
            selectedTags: [],
            emailUsers: [],
            lazyParams: { ...BasicLazyParams, sortField: 'createdAt', sortOrder: 1 },
            globalSearch: '',
            selectedUsers: [],
            totalRecords: 0,
            isSelectAll: false,
            selectedUsersCount: 0,
            contactsType: 'library',
            users: [],
            sheetColumns: [],

            mapFields: {
                "Email Address": null
            }
        }
        this.service = new Service();
    }


    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {

                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, this.getEmailUsers);
    }

    onPage = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getEmailUsers);
    }

    onSort = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getEmailUsers);
    }

    onFilter = (event) => {

        event['first'] = 0;
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters
                }
            };
        }, this.getEmailUsers);
        //  this.setState({ lazyParams: event }, this.getOrganizations);
    }

    getEmailUsers = () => {
        let { lazyParams, globalSearch, selectedTags } = this.state;


        if (selectedTags && selectedTags.length) {
            this.setState({
                isLoading: true,
                emailUsers: []
            });
            const url = `/emailusers/all?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`;
            this.service.post(url, { tags: selectedTags }, true).then((res) => {

                debugger
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        emailUsers: res.res.data,
                        totalRecords: res.res.totalRecords
                    })

                    // this.toast.show({ severity: 'success', summary: 'Success', detail: 'Users fetched successfully.', life: 3000 });
                } else {

                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
                }

            }).catch(e => {
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            })

        } else {

            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select tags', life: 3000 });
        }


    }

    getTags = () => {

        this.setState({
            isLoading: true,
            emailUsers: []
        });
        const url = `/emailusers-tags/all`;
        this.service.post(url, { status: this.state.selectedTags }, true).then((res) => {
            debugger
            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    tags: res.res.data,
                })
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }
        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })




    }

    saveUser = () => {
        let userData = trimObj(this.state.user)
        const formStatus = isFormValid(userFields, this.formFields.formValidations, userData);
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
        } else {
            let url = `/emailusers/save`;
            if (this.props.editUser) {
                url = `/emailusers/update`;
            }

            this.setState({
                isLoading: true,
            });

            this.service.post(url, userData, true).then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                    }, () => {
                        this.props.onSave(this.props.editUser ? true : false);
                    })
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.res.message, life: 3000 });
                }
            }).catch(e => {
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            })
        }
    }



    getSignatures = () => {

        let url = `/signature/all?limit=10000&page=1`

        this.setState({
            isLoading: true,
        });

        this.service.post(url, {}, true).then((res) => {

            debugger
            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    signatures: res.res.data,
                    // totalRecords: res.res.totalRecords
                })

                // this.toast.show({ severity: 'success', summary: 'Success', detail: 'Signatures fetched successfully.', life: 3000 });
            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })
    }

    getTemplates = () => {

        let url = `/template/names?limit=10000&page=1`

        this.setState({
            isLoading: true,
        });

        this.service.post(url, {}, true).then((res) => {

            debugger
            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    templates: res.res.data,
                    // totalRecords: res.res.totalRecords
                })

                // this.toast.show({ severity: 'success', summary: 'Success', detail: 'Signatures fetched successfully.', life: 3000 });
            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })
    }

    getConfigs = () => {

        let url = `/patient/get-assigned-mail?limit=10000&page=1`

        this.setState({
            isLoading: true,
        });

        this.service.get(url, {}, true).then((res) => {

            debugger
            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    configs: res.res.data,
                    // totalRecords: res.res.totalRecords
                })

                // this.toast.show({ severity: 'success', summary: 'Success', detail: 'Signatures fetched successfully.', life: 3000 });
            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })
    }

    componentDidMount() {
        if (this.props.isSessionChecked && !this.props.authenticated) {
            this.props.history.push('/login')
        }


        this.getSignatures();
        this.getTemplates();
        this.getConfigs();
        this.getTags();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSessionChecked != this.props.isSessionChecked && (this.props.isSessionChecked && !this.props.authenticated)) {
            this.props.history.push('/login')
        }

    }

    footer = () => {
        return (<div>
            <Button label='Save User' className='ma-m-lr10'
                onClick={this.saveUser}
            />
        </div>)
    }

    onChangeSignature = (signatureId) => {
        let _signature = this.state.signatures.find(a => a._id == signatureId);


        debugger
        this.setState((prevState) => {
            return {
                signatureId,
                message: {
                    ...prevState.message,
                    signature: _signature.signature,
                }
            };
        });

    }
    // onChangeConfig = (configId) => {
    //     //    let _template = this.state.templates.find(a => a._id == templateId);


    //     debugger


    //     this.setState((prevState) => {
    //         return {
    //             configId,
    //             message: {
    //                 ...prevState.message,
    //                 configId: configId
    //             }
    //         };
    //     });

    // }

    onChangeTemplate = (templateId) => {
        let _template = this.state.templates.find(a => a._id == templateId);


        debugger


        this.setState((prevState) => {
            return {
                templateId,
                message: {
                    ...prevState.message,
                    body: _template.message,
                    subject: _template.subject
                }
            };
        });

    }


    onSubmitClick = () => {
        const { currentStep, signatureId, templateId, configId } = this.state;
        if (currentStep == 0) {
            if (!templateId) {
                this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Template is required.', life: 3000 });
                return
            }
            if (!signatureId) {
                this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Signature is required.', life: 3000 });
                return
            }

            this.setState({
                currentStep: 1
            })


        } else if (currentStep == 1) {

            if (this.state.contactsType == 'library') {




                if ((this.state.selectedUsers && this.state.selectedUsers.length) || this.state.isSelectAll) {

                    this.setState({
                        isLoading: true,

                    });
                    let thisObj = this;




                    let payload = {
                        contactsType: this.state.contactsType,
                        users: this.state.selectedUsers,
                        message: this.state.message,
                        signatureId: this.state.signatureId,
                        templateId: this.state.templateId,
                        configId: this.state.configId,

                        isSelectAll: this.state.isSelectAll,
                        tags: this.state.selectedTags

                    };


                    const url = `/email-bulk/send`;
                    this.service.post(url, payload, true).then((res) => {

                        debugger
                        if (res && res.status && res.res.status) {
                            this.setState({
                                isLoading: false,
                            }, () => {
                                this.props.onSave(res.res.data)
                            })

                            // this.toast.show({ severity: 'success', summary: 'Success', detail: 'Users fetched successfully.', life: 3000 });
                        } else {

                            this.setState({
                                isLoading: false
                            });
                            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
                        }

                    }).catch(e => {
                        console.log(e);
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
                    })


                } else {
                    this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select users.', life: 3000 });
                }

            } else {
                if (this.state.users && this.state.users.length) {
                    this.setState({
                        currentStep: 2
                    })
                } else {
                    this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please upload users.', life: 3000 });
                }


            }
        } else if (currentStep == 2) {


            let payload = {
                contactsType: this.state.contactsType,
                users: this.state.users,
                message: this.state.message,
                signatureId: this.state.signatureId,
                templateId: this.state.templateId,
                mapFields: this.state.mapFields


                //configId: this.state.configId,

                // isSelectAll: this.state.isSelectAll,
                // tags: this.state.selectedTags

            };



            const url = `/email-bulk/send`;
            this.service.post(url, payload, true).then((res) => {

                debugger
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                    }, () => {
                        this.props.onSave(res.res.data)
                    })

                    // this.toast.show({ severity: 'success', summary: 'Success', detail: 'Users fetched successfully.', life: 3000 });
                } else {

                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
                }

            }).catch(e => {
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            })







        }
    }


    isSelectedAllItems = (tableItems, selectedItems, uniqField, isSelectAll) => {
        if (isSelectAll) {
            return true;
        }

        if (tableItems && tableItems.length) {
            let d = tableItems.every(a => selectedItems.find(b => a[uniqField] == b[uniqField]) ? true : false);

            return d;
        } else {
            return false;
        }
    }


    isSelected = (rowData, selectedItems, uniqField, isSelectAll) => {

        if (isSelectAll) {
            return true;
        }

        return selectedItems.find(a => a[uniqField] == rowData[uniqField]) ? true : false;
    }

    selectOrdeselect = (type, selectedItems, items, isSelect, selectedItemsField, uniqField) => {
        let selectedIts = _.cloneDeep(selectedItems);
        if (type == 'list') {
            if (isSelect) {
                items.forEach(i => {
                    let data = selectedIts.find(a => a[uniqField] == i[uniqField]);
                    if (!data) {
                        selectedIts.push(i);
                    }
                })
            } else {
                items.forEach(i => {
                    selectedIts = selectedIts.filter(a => a[uniqField] != i[uniqField]);
                })
            }
        } else {
            if (isSelect) {
                selectedIts.push(items)
            } else {
                selectedIts = selectedIts.filter((i) => i[uniqField] != items[uniqField]);
            }
        }

        this.setState({
            [selectedItemsField]: selectedIts,
            selectedUsersCount: selectedIts.length
        })
    }


    selectAllUser = (e) => {


        if (e.checked) {
            this.setState({
                isSelectAll: e.checked,
                selectedUsersCount: this.state.totalRecords,
                selectedUsers: []
            })
        } else {
            this.setState({
                isSelectAll: e.checked,
                selectedUsersCount: 0,
                selectedUsers: []
            })
        }


    }

    onChoiceChange(value, field) {

        this.setState({
            contactsType: value,
            selectedUsers: [],
            selectedUsersCount: 0,
            isSelectAll: false
        });


    }


    handleFile = (file) => {
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        let data = [];
        reader.onload = (e) => {
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            data = XLSX.utils.sheet_to_json(ws, { raw: false });
            this.setState({ data: data, cols: make_cols(ws['!ref']) });

            const newJsonSheet = [];
            for (let i = 0; i < data.length; i++) {
                let excelRowData = {
                    ...data[i],
                    __rowNum__: undefined
                };

                excelRowData = JSON.parse(JSON.stringify(excelRowData));

                newJsonSheet.push(excelRowData);
            }

            const users = newJsonSheet;


            debugger
            // .map((record) => ({
            //     ...record,
            //     mobile: `+91${record.mobile}`
            // }));
            debugger

            let sheetColumns = [];


            if (users && users.length && Object.keys(users[0]).length) {
                Object.keys(users[0]).forEach(key => {

                    sheetColumns.push(key);
                })
            }


            this.setState({ users, sheetColumns });
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
    };

    handleChange = (e) => {
        const files = e.target.files;
        if (files && files[0]) {
            this.setState({ fileSelected: files[0]['name'] });
            this.handleFile(files[0]);
            e.target.value = null;
        }
    };

    onChangeField = (key, value) => {
        this.setState((prevState) => {

            return {
                mapFields: {
                    ...prevState.mapFields,
                    [key]: value
                }
            };
        });

    }

    render() {
        const { user, formValidations, currentStep, contactsType, users, sheetColumns, mapFields } = this.state;
        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 >All Users</h2>
                    <Checkbox inputId={'dd'}
                        checked={this.state.isSelectAll}
                        onChange={this.selectAllUser}

                        className='select-all-chkbox'

                    ></Checkbox>
                    <label className='select-all-chklbl' for="dd">(Select all)</label>
                    {/* 
                    <Button className={`ma-ml10 cont-btn ${currentFilter != 1 ? 'p-button-outlined' : ''}`} label={'Booked'} onClick={() => this.onChangeStatusType(1)} />
                    <Button className={`ma-ml10 cont-btn ${currentFilter != 2 ? 'p-button-outlined' : ''}`} label={'Visisted'} onClick={() => this.onChangeStatusType(2)} />
                    <Button className={`ma-ml10 cont-btn ${currentFilter != 3 ? 'p-button-outlined' : ''}`} label={'Cancelled'} onClick={() => this.onChangeStatusType(3)} /> */}

                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md"
                        onChange={this.onGlobalSearch}
                        value={this.state.globalSearch}
                    />}
                </div>
                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        {/* <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                        <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" /> */}
                        {/* <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" /> */}
                    </ul>
                </div>
            </div>
        );
        console.log(this.state, 's')

        const header2 = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 >Users List</h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md"
                        value={this.state.globalFilter}
                        onChange={(e) => this.setState({
                            globalFilter: e.target.value
                        })} />}
                </div>
                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                        <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        {/* <li><i data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" /> */}
                        <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                    </ul>
                </div>
            </div>
        );

        console.log(this.props, 'state-appointment')
        return (<>
            <div className="ma-box-style2 create-org-w">
                <Button onClick={() => { this.props.onHide() }} icon="pi pi-arrow-left" className="p-button-rounded p-button-outlined" aria-label="Back" />
                <h2 className='create-org-title'>Create Compaign</h2>
                {/* <Steps
                    className='org-steps'
                    model={items} activeIndex={currentStep}
                    readOnly={false}
                /> */}

                <div className='ma-clearFix'>

                </div>
                {currentStep == 0 && <>
                    {/* <div className='p-grid'>
                        <div className='p-col-12 p-md-3'>
                            <p className='text-label'>From <span className='ma-required'>*</span></p>
                            <Dropdown value={this.state.configId}
                                options={this.state.configs}
                                optionLabel='email'
                                optionValue='_id'
                                // className='ma-w200  ma-mt10'
                                onChange={(e) => this.onChangeConfig(e.value)}


                                placeholder="Select From" />
                        </div>
                    </div> */}
                    <div className='p-grid'>
                        <div className='p-col-12 p-md-3'>
                            <p className='text-label'>Template <span className='ma-required'>*</span></p>
                            <Dropdown value={this.state.templateId}
                                options={this.state.templates}
                                optionLabel='name'
                                optionValue='_id'
                                // className='ma-w200  ma-mt10'
                                onChange={(e) => this.onChangeTemplate(e.value)}


                                placeholder="Select Template" />
                        </div>
                        <div className='p-col-12 p-md-3'>
                            <p className='text-label'>Signature<span className='ma-required'>*</span></p>
                            <Dropdown value={this.state.signatureId}
                                options={this.state.signatures}
                                optionLabel='signatureName'
                                optionValue='_id'
                                // className='ma-w200  ma-mt10'
                                onChange={(e) => this.onChangeSignature(e.value)}


                                placeholder="Select Signature" />
                        </div>

                    </div>

                    {/* <h4>Preview </h4> */}

                    <div className='ma-card'>
                        <p>Subject : <span>{this.state.message.subject}</span></p>
                        <div dangerouslySetInnerHTML={{ __html: this.state.message.body }}></div>
                        <div dangerouslySetInnerHTML={{ __html: this.state.message.signature }}></div>
                    </div>


                </>}
                {
                    currentStep == 1 && <>
                        {/* <h3></h3> */}
                        <h3></h3>
                        <div >
                            <p className='ma-label-s1'>Contacts From </p>
                            <RadioButton className="radio-inline" value='library' inputId="interanl" name="contactsType" onChange={(e) => this.onChoiceChange(e.value, 'contactsType')} checked={contactsType === 'library'} />
                            <label className='ma-label-s1' htmlFor="interanl">Library</label>
                            <RadioButton className="radio-inline ma-ml10" value='import' inputId="non-interanl" name="contactsType" onChange={(e) => this.onChoiceChange(e.value, 'contactsType')} checked={contactsType === 'import'} />
                            <label className='ma-label-s1' htmlFor="non-interanl">Import</label>
                        </div>
                        {
                            contactsType == 'library' ? <>
                                <p className='ma-label-s1'>Search Users By tags</p>
                                <MultiSelect value={this.state.selectedTags} options={this.state.tags} onChange={(e) => {
                                    this.setState({
                                        selectedTags: e.value
                                    })
                                }}
                                // filter={true}
                                // filterPlaceholder='Search here..'
                                // filterBy='label'
                                />
                                <Button label="Search" className='ma-m-lr10'
                                    onClick={this.getEmailUsers} />
                                <div className="card datatable-crud-demo ma-mt20 fetch-q-tabled">
                                    {/* <LazyTable ></LazyTable> */}
                                    <DataTable ref={(el) => this.dt = el}
                                        //  lazy
                                        scrollable
                                        responsiveLayout="scroll"
                                        //  autoLayout={true}
                                        rowHover
                                        value={this.state.emailUsers}
                                        // selection={this.state.selectedProducts}
                                        // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                                        dataKey="_id"
                                        //</div>={true}
                                        lazy
                                        onSort={this.onSort}
                                        onPage={this.onPage}
                                        onFilter={this.onFilter}
                                        first={this.state.lazyParams.first}
                                        last={this.state.totalRecords}
                                        rows={this.state.lazyParams.rows}
                                        totalRecords={this.state.totalRecords}
                                        paginatorLeft={() => {
                                            return <>
                                                <p className='avail-q'>Available users:  <span>{this.state.totalRecords}</span>, Selected users:  <span>{this.state.selectedUsersCount}</span>  </p>
                                                <p className='avail-q'></p>
                                            </>
                                        }}
                                        paginator
                                        rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 300, 500]}
                                        paginatorUser="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        currentPageReportUser="Showing {first} to {last} of {totalRecords} products"
                                        globalFilter={this.state.globalFilter}
                                        header={header}

                                    >
                                        <Column headerStyle={{ width: '4em' }} field='_id' header={<Checkbox inputId={'item.id'}
                                            checked={this.isSelectedAllItems(this.state.emailUsers, this.state.selectedUsers, '_id', this.state.isSelectAll)}
                                            onChange={(e) => { this.selectOrdeselect('list', this.state.selectedUsers, this.state.emailUsers, e.checked, 'selectedUsers', '_id') }}
                                        ></Checkbox>}
                                            body={(rowData) => {
                                                return <Checkbox inputId={'item.id'}
                                                    checked={this.isSelected(rowData, this.state.selectedUsers, '_id', this.state.isSelectAll)}
                                                    onChange={(e) => { this.selectOrdeselect('single', this.state.selectedUsers, rowData, e.checked, 'selectedUsers', '_id') }}
                                                ></Checkbox>
                                            }}>
                                        </Column>
                                        <Column field="name" header="Name" />
                                        <Column field="email" header="Email" />
                                        <Column sortable field="address" header="Address" />
                                        <Column sortable field="phoneNumber" header="Phone Number" />
                                        <Column sortable field="tags" header="Tags" body={(rowData) => {
                                            return <>
                                                {
                                                    rowData.tags.join(',')

                                                }
                                            </>
                                        }} />
                                        <Column sortable field="createdBy" header="Added By" body={(rowData) => {
                                            return <>
                                                {
                                                    rowData.createdBy?.name

                                                }
                                            </>
                                        }} />
                                        <Column
                                            // headerClassName='option-2'
                                            //headerStyle={{ width: "520px",  }}
                                            //headerStyle={{ display: 'inline-block   ' }}
                                            sortabl body={this.actionsUser} header="Actions" ></Column>
                                    </DataTable>

                                </div>
                            </> : <>
                                <div className="ask-a-doubt ma-main">
                                    <p className='spread-title'>Users Spreadsheet</p>
                                    <InputText
                                        className='p-inputtext-style1'
                                        value={this.state.fileSelected} disabled />
                                    {/* <span className='file-attach-name'>{this.state.fileSelected} </span> */}
                                    <label htmlFor="file" className='select-file'>Select File</label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="file"
                                        hidden={true}
                                        accept={SheetJSFT}
                                        onChange={this.handleChange}
                                        disabled={this.state.isLoading}
                                    />
                                    <div className='ma-clearFix'></div>

                                    <Button label="Upload data" className='ma-mt10'
                                        disabled={!this.state.users.length}
                                        onClick={() => {
                                            if (this.state.users && this.state.users.length) {
                                                this.uploadBulkUsers();
                                            }
                                        }}
                                        icon="pi pi-cloud-upload"
                                    />

                                    <Button label="Sample Sheet"
                                        className='p-button-outlined ma-mt10 ma-ml10 ma-bg-white'
                                        disabled={this.state.isLoading}
                                        onClick={() => {
                                            this.setState({
                                                isShowSampleFormat: true
                                            })
                                        }}
                                    //  icon="pi pi-cloud-upload"
                                    />
                                </div>
                                <div className="p-grid ma-toolbar">
                                    <div className="card datatable-crud-demo ma-m30">
                                        <DataTable ref={(el) => this.dt = el}
                                            value={this.state.users}
                                            dataKey="id"
                                            paginator
                                            rows={10}
                                            rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 300, 500]}
                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                            globalFilter={this.state.globalFilter}
                                            header={header2}>

                                            {
                                                sheetColumns.map(key => {
                                                    return <Column filterField={key} field={key} header={key} />

                                                })
                                            }
                                            {/* <Column filterField='Name' field="Name" header="Name" />
                                            <Column filterField='Email' field="Email" header="Email" />
                                            <Column filterField='Address' field="Address" header="Address" />
                                            <Column filterField='PhoneNumber' field="PhoneNumber" header="PhoneNumber" />
                                            <Column filterField='Tags' field="Tags" header="Tags" /> */}
                                        </DataTable>
                                    </div>
                                </div>
                            </>
                        }
                    </>}

                {
                    currentStep == 2 && <>
                        <h2>Map fields</h2>

                        {
                            Object.keys(mapFields).map(key => {
                                return <div> {key} :   <Dropdown value={mapFields[key]}
                                    options={this.state.sheetColumns}
                                    onChange={(e) => this.onChangeField(key, e.value)}
                                    placeholder="Select Field" /></div>

                            })
                        }




                    </>
                }

            </div>
            <Toolbar className="ma-toolbar ma-mt20" right={() => { return (<><Button label={`${this.state.currentStep == 0 ? 'Cancel' : 'Previous'}`} className='p-button-outlined' onClick={this.onBackClick} /> <Button label={this.state.currentStep == 3 ? `Submit` : 'Save & Proceed'} className='ma-m-lr10' onClick={this.onSubmitClick} /></>) }}></Toolbar>
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
            {this.state.isLoading && <LoadingComponent />}

        </>
        )
    }
}






const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    isSessionChecked: state?.user?.isSessionChecked,
});


export default connect(mapStateToProps, {

})(Authentication(withRouter(CreateUser)));
