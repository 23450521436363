import { BrowserRouter, Route } from 'react-router-dom';
import { Login } from './Login';
//import Appointments from './Appointments';
import 'primeflex/primeflex.css';
import './theme.scss';
import './fonts/fonts.css';
import './App.scss'
import HomePage from './HomePage';
//import BookAnAppointment from './Appointments/Patient/BookAnAppointment';
import EmailEditorComponent from './EmailEditor';
import EmailSignature from './EmailSignature';
import EmailTemplate from './EmailTemplate';
import EmailUsers from './EmailUsers';
import BulkEmails from './BulkEmails';
import BulkEmailUsersUpload from './EmailUsers/bulkUpload';
import EmailConfig from './EmailConfig';
import AdminUser from './AdminManagement/AdminUser';
import Roles from './AdminManagement/Roles';

export const App = () => {
  return (
    <div>
      <BrowserRouter>
        <div className="App">
          <Route exact path='/' component={HomePage} />
          <Route exact path='/login' component={Login} />
          {/* <Route exact path='/doctor' component={Doctor} />
          <Route exact path='/patient' component={Patient} /> */}
          {/* <Route exact path='/appointments' component={Appointments} /> */}
          {/* <Route exact path='/appointment-book' component={BookAnAppointment} /> */}
          <Route exact path='/email' component={EmailEditorComponent} />
          <Route exact path='/signature' component={EmailSignature} />
          <Route exact path='/template' component={EmailTemplate} />
          <Route exact path='/emailusers' component={EmailUsers} />
          <Route exact path='/bulk-emailusers' component={BulkEmailUsersUpload} />
          <Route exact path='/bulk-emails' component={BulkEmails} />
          <Route exact path='/config' component={EmailConfig} />
          <Route exact path='/admin' component={AdminUser} />
          <Route exact path='/roles' component={Roles} />

          {/* <Route exact path='/appointmentSuccess' component={AppointmentSuccess} />
          <Route exact path='/appointmentCancel' component={AppointmentCancel} />
          <Route exact path='/appointmentView' component={ViewAppointments} />
          <Route exact path='/docAppointmentCancel' component={DocAppointmentCancel} />
          <Route exact path='/patAppointmentView' component={ViewPatientAppointments} />
          <Route exact path='/feedback' component={Feedback} />
          <Route exact path='/feedbackSuccess' component={FeedbackSuccess} /> */}
        </div>
      </BrowserRouter>
    </div>
  );
}

