import React, { Component } from 'react';
import { Menubar } from 'primereact/menubar';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RadioButton } from 'primereact/radiobutton';
import Authentication from '../session/index'
import { TieredMenu } from 'primereact/tieredmenu';
import LoadingComponent from 'src/LoadingComponent';
import './styles.scss'


const getThemes = () => {
    if (localStorage.getItem('themes')) {
        return JSON.parse(localStorage.getItem('themes'));
    } else {
        return [
            {
                name: "Light Blue",
                value: null,
                isSelected: true
            },
            {
                name: "Pink",
                value: 'pink',
                isSelected: false
            },
            {
                name: "Green",
                value: 'green',
                isSelected: false
            },
            {
                name: "Yellow",
                value: 'yellow',
                isSelected: false
            },
        ]
    }
}


class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            themes: getThemes(),
            isShowChangeRole: false,
        }


    }




    onLogout() {
        localStorage.clear();
        this.props.history.push('/')
        window.location.reload();

    }



    getNavbarModules = () => {
        let ModuleMenuItems = [{
            label: 'Home',
            className: `${(window.location.pathname == '/') ? 'ma-menu-active' : 'ddd'}`,
            command: () => {
                this.props.history.push('/')
            },
        },
        // {
        //     label: 'Appointments',
        //     className: `${(window.location.pathname == '/appointments') ? 'ma-menu-active' : 'ddd'}`,
        //     command: () => {
        //         this.props.history.push('/appointments')
        //     },
        // },
        {
            label: 'Admin Management',
            className: `${(window.location.pathname == '/admin') ? 'ma-menu-active' : 'ddd'}`,

            items: [

                {
                    label: 'Admin Management',
                    // className: `${window.location.pathname == '/organization' ? 'ma-sub-menu-active' : 'ddd'}`,
                    // isPermRequired: true,
                    // uId: '0761fb3e-2f31-45e5-a431-c3d2a745d1a3',
                    items: [

                        {
                            label: 'Admin Users',
                            // className: `${window.location.pathname == '/organization' ? 'ma-sub-menu-active' : 'ddd'}`,
                            command: () => {
                                this.props.history.push('/admin')
                            },
                            isPermRequired: true,
                            uId: '0761fb3e-2f31-45e5-a431-c3d2a745d1a3',
                            // icon: 'pi pi-fw pi-align-left'
                        }
                        ,
                        {
                            label: 'Admin Roles',
                            // className: `${window.location.pathname == '/organization' ? 'ma-sub-menu-active' : 'ddd'}`,
                            command: () => {
                                this.props.history.push('/roles')
                            },
                            isPermRequired: true,
                            uId: 'c54bee0c-bf05-4739-b88c-eff7a1850008',
                            // icon: 'pi pi-fw pi-align-left'
                        }]
                    //isPermRequired: true,
                    //uId: '8661adca-b5c5-49e0-abe1-67ea1f6839c4',
                    // icon: 'pi pi-fw pi-align-left'
                },
                {
                    label: 'Configuration',
                    // className: `${window.location.pathname == '/organization' ? 'ma-sub-menu-active' : 'ddd'}`,
                    command: () => {
                        this.props.history.push('/config')
                    },
                    isPermRequired: true,
                    uId: 'a7ee7332-791a-4fd5-b457-1226b40186b3',
                    // icon: 'pi pi-fw pi-align-left'
                }
            ]
        },

        {
            label: 'Email Services',
            className: `${(window.location.pathname == '/signature' || window.location.pathname == '/template') ? 'ma-menu-active' : 'ddd'}`,
            // command: () => { this.props.history.push('/organization') },
            //command: () => { console.log('abcdef'); },
            //url: '/home'
            //  isPermRequired: false,

            items: [

                {
                    label: 'Email Compaign',
                    // className: `${window.location.pathname == '/organization' ? 'ma-sub-menu-active' : 'ddd'}`,
                    command: () => {
                        this.props.history.push('/bulk-emails')
                    },
                    isPermRequired: true,
                    uId: '6fa2e1dc-b104-410e-ab7b-e471c8b89334',
                    // icon: 'pi pi-fw pi-align-left'
                }, {
                    label: 'Email Signatures',
                    // className: `${window.location.pathname == '/organization' ? 'ma-sub-menu-active' : 'ddd'}`,
                    command: () => {
                        this.props.history.push('/signature')
                    },
                    isPermRequired: true,
                    uId: '7d934554-70c2-4658-99af-143fff1ea1a0',
                    // icon: 'pi pi-fw pi-align-left'
                },
                {
                    label: 'Email Templates',
                    // className: `${window.location.pathname == '/organization' ? 'ma-sub-menu-active' : 'ddd'}`,
                    command: () => {
                        this.props.history.push('/template')
                    },
                    isPermRequired: true,
                    uId: '7f7086de-6c86-4855-985a-a25d0bbc5527',
                    // icon: 'pi pi-fw pi-align-left'
                },
                {
                    label: 'Email Users',
                    // className: `${window.location.pathname == '/organization' ? 'ma-sub-menu-active' : 'ddd'}`,
                    command: () => {
                        this.props.history.push('/emailusers')
                    },
                    isPermRequired: true,
                    uId: 'f30eb241-f05f-4700-bde9-90c7c22d5e96',
                    // icon: 'pi pi-fw pi-align-left'
                },
                {
                    label: 'Bulk Email Users Upload',
                    // className: `${window.location.pathname == '/organization' ? 'ma-sub-menu-active' : 'ddd'}`,
                    command: () => {
                        this.props.history.push('/bulk-emailusers')
                    },
                    isPermRequired: true,
                    uId: '6288619d-c523-4b4b-aec7-291827e2179a',
                    // icon: 'pi pi-fw pi-align-left'
                },

            ]

        }
        ];

        let assignedM = [];
        if (this.props.authenticated) {
            assignedM = ModuleMenuItems
        }



        console.log(assignedM)

        return assignedM;


    }

    changeTheme = (selectedTheme) => {

        const themes = this.state.themes.map((theme) => {
            if (theme.value == selectedTheme.value) {
                theme.isSelected = true;
            } else {
                theme.isSelected = false;

            }
            return theme;
        })
        this.setState({
            themes
        });



        localStorage.setItem('themes', JSON.stringify(themes));
        localStorage.setItem('data-theme', selectedTheme.value);
        document.documentElement.setAttribute('data-theme', localStorage.getItem('data-theme'));
        //  window.location.reload();
    }


    getThemes = () => {
        const { themes } = this.state;
        return <ul className='ma-p10'>{themes.map(theme => {
            return <li key={theme.name} className='ma-m10' >
                <RadioButton className="radio-inline" value={theme.value} inputId={theme.value} name="theme"
                    onChange={() => {
                        this.changeTheme(theme)
                    }}
                    checked={theme.isSelected}
                />
                <label className='ma-label-s1' htmlFor={theme.value}>{theme.name}</label>
            </li>
        })}  </ul>


    }



    render() {

        const items = [
            {
                label: 'Theme',
                icon: 'pi pi-slack',
                items: [
                    {
                        template: <>{this.getThemes()}</>
                    }
                ]
            },

            {
                separator: true
            },
            {
                label: 'Log Out',
                icon: 'pi pi-fw pi-power-off',
                command: () => { this.onLogout() }
            }
        ];

        console.log(this.props.user, 'user')
        return <div>
            <div className="ma-menubar-w">

                <div className="p-grid ma-menubar ma-no-pm">
                    <div className="p-col-12 p-md-2 ma-no-p">
                        <h2 className='app-title' onClick={() => { this.props.history.push('/') }} >SendShortly </h2>
                    </div>
                    <div className="p-col-12 p-md-6 ma-no-p">
                        <Menubar model={this.getNavbarModules()}
                            className="m-menu-bar "
                        />
                    </div>
                    {
                        this.props.authenticated ? <>
                            <div className="p-col-12 p-md-4 ma-right ">
                                <div style={{ width: '40px', float: 'right' }}>
                                    <img src={this.props?.user?.user?.photo} style={{ height: '34px', borderRadius: '50%' }} />
                                </div>
                                <div style={{ marginRight: '50px' }} onClick={(event) => this.menu.toggle(event)}>
                                    <p style={{ textAlign: 'right' }} className='ma-profile'>
                                        {/* <i className='pi pi-angle-down'></i> */}
                                        {this.props?.user?.user?.orgName} - {this.props?.user?.user?.name}</p>
                                </div>
                            </div>
                        </> : <>
                            <div className="p-col-12 p-md-4 ma-right ">
                                <p className='ma-right login-txt' onClick={() => { this.props.history.push('/login') }}>Login / Register</p>
                            </div>

                        </>
                    }
                </div>

                <TieredMenu model={items} popup ref={el => this.menu = el} />

                {
                    this.props.isLoading && <LoadingComponent />
                }



            </div>
            <div className='body-container'>
                {this.props.children}
            </div>
            <footer className='footer'>
                <p className='footer-p'>&copy; SendShortly 2022</p>
                <p className='all-rights'>All rights reserved.</p>
            </footer>
        </div>
    }

}


const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
});


export default connect(mapStateToProps, {
})(Authentication(withRouter(Header)));

