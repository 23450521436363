import React, { Component } from 'react'
import { Dialog } from 'primereact/dialog';
import Service from 'src/Service';
import { Toolbar } from 'primereact/toolbar';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import userFields from './user.json';
import LoadingComponent from 'src/LoadingComponent';
import { Specialization } from 'src/Utils/constants';
import { getFormFields, isFormValid, onEmailChange, onChipsChange, onNumberChange, onTextChange } from 'src/Utils/formHelper';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from '../session/index'
import Header from 'src/Header';
import './styles.scss'
import { InputTextarea } from 'primereact/inputtextarea';
import ClassicEditor from 'src/ClassicEditor';
import { Chips } from 'primereact/chips';
import { trimObj } from 'src/Utils';

class CreateUser extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(userFields, this.props.editUser ? this.props.editUser : {});
        this.state = {
            user: this.formFields.data,
            formValidations: this.formFields.formValidations,
        }
        this.service = new Service();
    }

    saveUser = () => {
        let userData = trimObj(this.state.user)
        const formStatus = isFormValid(userFields, this.formFields.formValidations, userData);
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
        } else {
            let url = `/emailusers/save`;
            if (this.props.editUser) {
                url = `/emailusers/update`;
            }

            this.setState({
                isLoading: true,
            });

            this.service.post(url, userData, true).then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                    }, () => {
                        this.props.onSave(this.props.editUser ? true : false);
                    })
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.res.message, life: 3000 });
                }
            }).catch(e => {
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            })
        }
    }

    componentDidMount() {
        if (this.props.isSessionChecked && !this.props.authenticated) {
            this.props.history.push('/login')
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSessionChecked != this.props.isSessionChecked && (this.props.isSessionChecked && !this.props.authenticated)) {
            this.props.history.push('/login')
        }

    }

    footer = () => {
        return (<div>
            <Button label={`${this.props.editUser ? 'Update' : 'Save'} User`} className='ma-m-lr10'
                onClick={this.saveUser}
            />
        </div>)
    }


    render() {
        const { user, formValidations } = this.state;

        console.log(this.props, 'state-emailUser')
        return (<>
            <Dialog
                header={`${this.props.editUser ? 'Edit' : 'Create'} User`}
                blockScroll={true}
                draggable={false}
                closeOnEscape={true}
                dismissableMask={true}
                visible={true}
                footer={this.footer}
                closable={true}
                style={{ width: '90%', height: '90vh' }}
                onHide={this.props.onHide}>

                <div>
                    <div className="p-grid ">
                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label' >Email<span className='ma-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                            <InputText value={user.email}
                                className='ma-w100p p-inputtext-style1'
                                onChange={(e) => { onEmailChange(e.target.value, 'email', this, userFields, user, formValidations, 'user', 'formValidations') }} />
                            {formValidations && !formValidations.fields['email'].isValid && <p className="p-error">{formValidations.fields['email'].errorMsg}</p>}
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label'>Name</p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                            <InputText value={user.name}
                                className='ma-w100p p-inputtext-style1'
                                onChange={(e) => { onTextChange(e.target.value, 'name', this, userFields, user, formValidations, 'user', 'formValidations') }} />
                            {formValidations && !formValidations.fields['name'].isValid && <p className="p-error">{formValidations.fields['name'].errorMsg}</p>}
                        </div>
                    </div>

                    <div className="p-grid ">
                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label' >Phone Number</p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                            <InputText value={user.phoneNumber}
                                className='ma-w100p p-inputtext-style1'
                                onChange={(e) => { onNumberChange(e.target.value, 'phoneNumber', this, userFields, user, formValidations, 'user', 'formValidations') }} />
                            {formValidations && !formValidations.fields['phoneNumber'].isValid && <p className="p-error">{formValidations.fields['phoneNumber'].errorMsg}</p>}
                        </div>
                    </div>
                    <div className="p-grid ma-mt20">

                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label'>Address</p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                            <InputTextarea value={user.address}
                                rows={3}
                                className='ma-w100p p-inputtext-style1'
                                onChange={(e) => { onTextChange(e.target.value, 'address', this, userFields, user, formValidations, 'user', 'formValidations') }} />
                            {formValidations && !formValidations.fields['address'].isValid && <p className="p-error">{formValidations.fields['address'].errorMsg}</p>}
                        </div>
                    </div>
                    <div className="p-grid ">
                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label' >Tags<span className='ma-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                            <Chips
                                className=''
                                value={user.tags}
                                onChange={(e) => onChipsChange(e.value, 'tags', this, userFields, user, formValidations, 'user', 'formValidations')}
                                separator=','
                            ></Chips>
                            {formValidations && !formValidations.fields['tags'].isValid && <p className="p-error">{formValidations.fields['tags'].errorMsg}</p>}
                        </div>
                    </div>
                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}
            </Dialog>
        </>
        )
    }
}






const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    isSessionChecked: state?.user?.isSessionChecked,
});


export default connect(mapStateToProps, {

})(Authentication(withRouter(CreateUser)));
