import React, { Component } from 'react';
import { connect } from 'react-redux';

export const PERMISSIONS = {

    // admin USER--start
    ADMIN_USER: '0761fb3e-2f31-45e5-a431-c3d2a745d1a3',
    ADMIN_USER_LIST: "24d85229-41a3-433b-84ff-ff8c4cec0f28",
    ADMIN_USER_ADD: 'e6ea332b-03d8-4a2f-a3e4-470b95db2985',
    ADMIN_USER_EDIT: 'a4533bbe-cff0-4757-a77a-328eefcc35be',
    ADMIN_USER_DELETE: '6f342d2b-0d3b-4de2-8a8c-23bcbcc144f6',
    ADMIN_USER_GENERAL_SEARCH: "234a2dd0-478f-4340-9d24-a425b56d23a8",
    // admin USER--end


    //CONFIGURATIONS--start
    CONFIGURATIONS: "a7ee7332-791a-4fd5-b457-1226b40186b3",
    CONFIGURATIONS_GENERAL_SEARCH: "1579e448-f5a9-415b-b40b-f4957c10021f",
    CONFIGURATIONS_LIST: "7bf99732-521f-4fad-835b-db0f255f9e2b",
    CONFIGURATIONS_ADD: "1685cff3-1841-423b-b6e4-a7575da8c170",
    CONFIGURATIONS_EDIT: "75df3049-f24b-4b3d-8852-d0cbb9453676",
    CONFIGURATIONS_DELETE: "423e9c5e-34ee-497a-97f1-40404fe37d4c",
    //CONFIGURATIONS--end


    //SIGNATURES--start
    SIGNATURES: "7d934554-70c2-4658-99af-143fff1ea1a0",
    SIGNATURES_GENERAL_SEARCH: "63fbbc39-2886-407d-9a78-dd05611de7ba",
    SIGNATURES_LIST: "bb8ae6db-69e0-4c34-9dcb-a5f8a886fac7",
    SIGNATURES_ADD: "b29e50d9-b99d-413d-9b9d-ffc006c59156",
    SIGNATURES_VIEW: "5d0c24be-0359-4b97-9d13-eafd135a8448",
    SIGNATURES_EDIT: "cfe9273b-3e60-481d-978c-e3f714a16952",
    SIGNATURES_DELETE: "58120ad4-ed15-4d62-9fbd-340a60e7e747",
    //SIGNATURES--end

    //TEMPLATES--start
    TEMPLATES: "7f7086de-6c86-4855-985a-a25d0bbc5527",
    TEMPLATES_GENERAL_SEARCH: "d0c44bb1-93a3-443d-863f-8a2d66d55fb0",
    TEMPLATES_LIST: "b8c9ae81-7775-48b3-8ac8-d8e95a8374e5",
    TEMPLATES_ADD: "d373842d-b444-4e82-b329-261a3c76d1c1",
    TEMPLATES_VIEW: "662ddfe0-0c64-45bc-b65a-3a229d53d762",
    TEMPLATES_EDIT: "7cfea001-758e-4163-afdd-6765463532f3",
    TEMPLATES_DELETE: "0f364db7-bcae-4908-a621-f4e04f987c66",
    //TEMPLATES--end


    //EMAIL_USERS--start
    EMAIL_USERS: "f30eb241-f05f-4700-bde9-90c7c22d5e96",
    EMAIL_USERS_GENERAL_SEARCH: "e45ee36b-5314-4ee7-99e0-9836f01437a3",
    EMAIL_USERS_LIST: "46f8a8d5-a3c7-429c-acf8-601d187d8bc1",
    EMAIL_USERS_ADD: "95306d25-9889-43f7-948e-3844b2283fa2",
    EMAIL_USERS_VIEW: "5c8d87dc-d61c-4455-8a53-1244c837542d",
    EMAIL_USERS_EDIT: "c45b9af9-4e29-44f0-bd57-a192f8bc45d8",
    EMAIL_USERS_DELETE: "22af8256-b2e4-46d8-8017-93eca2070bc9",
    //EMAIL_USERS--end

    //BULK EMAIL_USERS--start
    BULK_EMAIL_USERS: "6288619d-c523-4b4b-aec7-291827e2179a",
    BULK_EMAIL_USERS_GENERAL_SEARCH: "d976c843-1e7b-40e9-afd3-be126291dd3f",
    BULK_EMAIL_USERS_GENERAL_DOWNLOAD: "308f906e-0711-40a9-b2d4-ef1ae2d91eb4",
    BULK_EMAIL_USERS_LIST: "4b63eb51-34c7-4355-ab63-c4a54fffbd3e",
    BULK_EMAIL_USERS_SHOW_DETAILS: "ff1e04ac-2fe6-4794-b894-08aaac98bfe2",
    BULK_EMAIL_USERS_NEW_UPLOAD: "b973856f-2964-463b-9a13-0db53e82ea9a",
    //BULK EMAIL_USERS--end

    //EMAIL_COMPAIGN--start
    EMAIL_COMPAIGN: "6fa2e1dc-b104-410e-ab7b-e471c8b89334",
    EMAIL_COMPAIGN_GENERAL_SEARCH: "b5df79b1-471b-42c2-bccd-c11e3f4bc7a3",
    EMAIL_COMPAIGN_LIST: "fd1676ee-db41-4dd7-afee-1500654caeaf",
    EMAIL_COMPAIGN_SHOW_DETAILS: "cdc358d6-01f2-4c74-95ec-d49ff987b441",
    EMAIL_COMPAIGN_CREATE_COMPAIGN: "b1efd9e5-65b8-4178-8f47-93425241deaf",
    EMAIL_COMPAIGN_REFRESH: "3ad9089f-87b0-414a-ba93-2f9fd854b95d",
    //BULK EMAIL_COMPAIGN--end

    //ROLE START
    ROLE: 'c54bee0c-bf05-4739-b88c-eff7a1850008',
    ROLE_LIST: "32fc6f72-91a2-4827-a040-c02b0a32a44f",
    ROLE_ADD: 'f84c3967-d1a8-461a-922b-a76df3b4fce3',
    ROLE_EDIT: 'bcd084a9-f924-4d78-81b7-b122ebeeff0f',
    // ROLE_DELETE: '6f342d2b-0d3b-4de2-8a8c-23bcbcc144f6',
    ROLE_GENERAL_SEARCH: "3650ad78-86f5-4677-a6a4-11a1655f13e2",
    //ROLE END
}


const getIsBypassAuth = () => {
    return process.env.REACT_APP_BYPASS_AUTHORIZATION === 'true';
}



export const PERM_GROUPS = {
    ORG: [
        PERMISSIONS.CREATE_ORGANIZATION,
        PERMISSIONS.EDIT_ORGANIZATION,
        PERMISSIONS.CHANGE_ORG_STATUS,
        PERMISSIONS.VIEW_ORGANIZATION,
    ]
}
class Authorizer extends Component {
    constructor(props) {
        super(props);
    }


    isAuthorized = () => {
        if (getIsBypassAuth()) {
            return true;
        }

        // if (this.props.userInfo && this.props.userInfo.isSuperAdmin) {
        //     return true;
        // }

        return this.props.permissionIds && this.props.permissionIds.length && this.props.permissionIds.includes(this.props.permId)
    }

    render() {
        console.log(this.props.permissionIds, 'perm')
        return <React.Fragment>
            {this.isAuthorized() && <React.Fragment>
                {this.props.children}
            </React.Fragment>}
        </React.Fragment>;
    }
}


export const isAuthorized = (permissionIds, permId, userInfo) => {
    if (getIsBypassAuth()) {
        return true;
    }


    // if (userInfo && userInfo.isSuperAdmin) {
    //     return true;
    // }

    return permissionIds.includes(permId);
}


export const isAuthorizedOne = (permissionIds, permIds) => {
    if (getIsBypassAuth()) {
        return true;
    }

    let isAuthorized = false;
    for (let index = 0; index < permIds.length; index++) {
        const permId = permIds[index];
        if (permissionIds.includes(permId)) {
            isAuthorized = true;
            break
        }
    }


    return isAuthorized;
}


const mapStatesToProps = (state) => ({
    //  permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    permissionIds: state?.user?.user?.permissionIds,
    //userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});

export default connect(mapStatesToProps, {})(Authorizer);