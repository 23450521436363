import React, { Component } from 'react';
import { selectAllRoles } from '../../store/selectors/authorize';
import { Dialog } from 'primereact/dialog';
// import SvgViewer from './../../customComponents/svgViewer';
import Header from './../../Header';
import { InputSwitch } from 'primereact/inputswitch';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { connect } from 'react-redux';
import { Toast } from 'primereact/toast';
import { Menu } from 'primereact/menu';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import CreateRole from './createRole';

import './styles.scss'
import Authorizer, { PERMISSIONS, isAuthorized } from './../../session/authorize';
import Authentication from './../../session';
// import { WarningIcon } from '../../svgIcons';
import LoadingComponent from './../../LoadingComponent';
import { warningDailogInit } from 'src/Utils';
import Service from './../../Service';
const BasicLazyParams = {
    first: 0,
    rows: 10,
    page: 0,
    search: '',
    sortField: null,
    sortOrder: null,
    filters: {
        'name': { value: '', matchMode: 'contains' },
        'country.name': { value: '', matchMode: 'contains' },
        'company': { value: '', matchMode: 'contains' },
        'representative.name': { value: '', matchMode: 'contains' },
    }
}


class RolesList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            globalFilter: '',
            globalSearch: '',
            columns: [
                { columnName: 'Role Name' },
            ],


            roles: [],
            editRoleData: null,
            showSearch: false,
            isShowCreate: false,
            isAdd: false,
            dialogData: {},

            warningDialog: warningDailogInit,
            totalRecords: 0,
            globalSearch: '',
            lazyParams: BasicLazyParams
        }
        this.service = new Service();
    }

    getRoles = () => {
        this.setState({
            isLoading: true
        });
        let lazyParams = this.state.lazyParams;
        let url = `/authorize/getRoles?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${this.state.globalSearch}`;
        this.service.get(url, true).then(res => {

            if (res && res.res && res.res.data) {
                this.setState({
                    roles: res.res.data.map((r, index) => {
                        r.slNo = index + 1;
                        return r;
                    }),
                    totalRecords: res.res.totalRecords,
                    isLoading: false
                });
            } else {
                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch((e) => {
            this.setState({
                isLoading: false
            })
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        });

    }


    componentDidMount() {
        //this.props.getRolesListReq();
        this.getRoles();

    }

    changeStatus = (e, rowData) => {
        let data = { ...rowData }
        if (e.value == true) {
            data.status = 'Active'
        } else {
            data.status = 'InActive'
        }

        this.setState({
            warningDialog: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `You are changing this Role status ${e.value ? 'InActive' : 'Active'} to ${e.value ? 'Active' : 'InActive'}`
            },
            editRoleData: data
        });
    }



    onConfirmChange = () => {

        this.setState({
            warningDialog: warningDailogInit,
            isLoading: true
        })
        const url = `/authorize/updateRole`;
        this.service.put(url, this.state.editRoleData, true).then((res) => {
            if (res && res.status) {
                this.setState({
                    isLoading: false,

                }, () => {
                    this.onRoleCreate(false);
                })
                // this.toast.show({ severity: 'success', summary: 'Role Updated', detail: res.res.message, life: 3000 });

            } else {

                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }


        }).catch(e => {
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            this.setState({
                isLoading: false
            });
        });

    }
    exportCSV = () => {
        this.dt.exportCSV();
    }


    editTemplate = (rowData) => {
        return (<>
            <div className="p-d-flex ma-mlr10 user-edit-t">
                <Authorizer permId={PERMISSIONS.ROLE_EDIT} >
                    {/* <Button label={`Edit`} onClick={() => this.showEditRoleDialog(rowData)} /> */}
                    <span data-pr-tooltip="Edit User" data-pr-position="bottom" className={`ma-ml10 anal${rowData._id.substr(4)}`} onClick={() => this.showEditRoleDialog(rowData)} >d
                        {/* <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="20" height="20" /> */}
                    </span>
                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.anal${rowData._id.substr(4)}`} />
                </Authorizer>

            </div>
        </>
        );
    }


    onPage = (event) => {

        this.setState({ lazyParams: event }, this.getRoles);
    }



    showEditRoleDialog = (editRoleData) => {

        this.setState({
            editRoleData,
            isShowCreate: true,
            isAdd: false
        });
    }

    showRoleCreateDialog = () => {

        this.setState({
            isShowCreate: true,
            editRoleData: null,
            isAdd: true
        });

    }

    hideRoleCreateDialog = () => {
        this.setState({
            isShowCreate: false,
            editRoleData: null,
            isAdd: false
        });
    }

    onRoleCreate = (isCreate, data) => {


        this.toast.show({ severity: 'success', summary: isCreate ? 'Role Created' : 'Role Updated', detail: `Role Successfully ${isCreate ? 'Created' : 'Updated'}`, life: 3000 });
        this.setState({
            isShowCreate: false,
            editRoleData: null,
            lazyParams: BasicLazyParams
        }, () => {
            this.getRoles()
            //  this.props.getRolesListReq();
        });

    }

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, this.getRoles);
    }

    render() {

        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 >Roles</h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md"
                        value={this.state.globalSearch}
                        onChange={this.onGlobalSearch} />}
                </div>
                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        <Authorizer permId={PERMISSIONS.ROLE_GENERAL_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        {/* <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_VIWECOLUMNS} >
                            <li><i data-pr-tooltip="View Columns" 
                            onClick={(event) => this.menu.toggle(event)}
                             data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer> */}


                        <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_DOWNLOADCSV} >
                            <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer>
                    </ul>
                </div>
                {/* <Menu
                    style={{ width: "100px" }}
                    model={[
                        {
                            template: (<div className='search-filter'>
                                <h4 className="sort-title">Sort & Filter</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <li>{column.columnName}</li>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} /> */}
            </div>
        );

        return (
            <Header>
                <div className='ma-main'>
                    {
                        this.state.isLoading && <>
                            <LoadingComponent />
                        </>
                    }

                    <Authorizer permId={PERMISSIONS.ROLE_ADD} >
                        <Button label={`+ Add`} className='' onClick={this.showRoleCreateDialog} />
                    </Authorizer>
                    <Authorizer permId={PERMISSIONS.ROLE_LIST} >
                        <div className="card datatable-crud-demo ma-m30 ">
                            <DataTable ref={(el) => this.dt = el}
                                //  lazy
                                //  value={this.state.roles}
                                value={this.state.roles}
                                // selection={this.state.selectedProducts}
                                // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                                dataKey="id"
                                paginator
                                lazy
                                onPage={this.onPage}
                                first={this.state.lazyParams.first}
                                last={this.state.totalRecords}
                                rows={this.state.lazyParams.rows}
                                totalRecords={this.state.totalRecords}
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                responsiveLayout="scroll"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                globalFilter={this.state.globalFilter}
                                header={header}>
                                <Column sortable field="slNo" header="Sr. No" body={(rowData) => {
                                    return <span>
                                        {(this.state.lazyParams.page * this.state.lazyParams.rows) + rowData.slNo}
                                    </span>
                                }} />
                                <Column sortable field="roleName" header="Role" />
                                <Column sortable body={(rowData) => {
                                    return (
                                        <div className="ma-status">

                                            <span className="ma-status-text">{rowData.status} </span>
                                            <Authorizer permId={PERMISSIONS.ROLE_EDIT} >
                                                <InputSwitch
                                                    onChange={(e) => { this.changeStatus(e, rowData) }}
                                                    checked={rowData.status == 'Active'} />
                                            </Authorizer>
                                        </div>
                                    )
                                }} field="status" header="Status" />
                                {/* <Column field="chapterLongDescription" header="Chapter Long Description" /> */}
                                {/* <Column field="isChapterActive" header="Status" body={this.statusTemplate} /> */}
                                <Column body={this.editTemplate} header="Actions"></Column>
                                {/* <Column body={this.showChaptersTemplate}></Column> */}
                            </DataTable>





                            {/* {this.state.isShowCreate && <CreateChapter isAdd={this.state.isAdd} hideCreateDialog={this.hideCreateChapterDialog} editChapterData={this.state.editChapterData} addChapter={this.addChapter} />} */}

                            <Toast ref={(el) => this.toast = el} position="bottom-right" />

                            {
                                this.state.isShowCreate && <CreateRole
                                    onRoleCreate={this.onRoleCreate}
                                    isAdd={this.state.isAdd}
                                    hideRoleCreateDialog={this.hideRoleCreateDialog}
                                    editRoleData={this.state.editRoleData}
                                />
                            }
                        </div>
                    </Authorizer>
                    <Dialog
                        header={<></>}
                        draggable={false}
                        closeOnEscape={true}
                        className='ma-alert-box'
                        blockScroll={true}
                        dismissableMask={true}
                        closable={false}
                        visible={this.state.warningDialog.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                        footer={() => {
                            return (<div>
                                <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog: warningDailogInit, editRoleData: null }) }} />
                                <Button label="Yes" className='ma-m-lr10' onClick={this.onConfirmChange} />

                            </div>)
                        }}
                        onHide={() => this.setState({ warningDialog: warningDailogInit, editRoleData: null })}>
                        <div>
                            <span className="warning-icon" >
                                {/* <WarningIcon width={"100%"} height={"100%"} /> */}
                            </span>
                        </div>
                        <p className='ma-alert-head'>{this.state.warningDialog.headerMsg}</p>
                        <p className='ma-alert-msg'>{this.state.warningDialog.message}</p>
                    </Dialog>

                </div>

            </Header>
        )
    }
}




const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
    // updateRoleReq: (data) => dispatch(updateRole(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(RolesList);